
import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator';
import * as TwitterServices from '@/services/TwitterServices'
import { FullTweet } from '@/types';
import { format } from 'date-fns';
@Component
export default class CreateTwitterReportDialog extends Vue {
    @VModel({ required: true }) show!: boolean;
    @Prop({ required: true }) tweet!: string;

    propTweet: FullTweet | null = null
    relatedTweet: FullTweet | null = null
    replyTweets = [] as FullTweet[]

    formatDate(date: string) {
        return format(new Date(date), 'dd/MM/yyyy HH:mm')
    }

    loading = false
    @Watch('tweet', { immediate: true })
    async onTweetChanged() {
        this.loading = true
        if (this.tweet === '') return
        const tweet = await TwitterServices.GetTweet(this.tweet)
        this.propTweet = tweet

        if (tweet.replyTo) {
            const relatedTweet = await TwitterServices.GetTweet(tweet.replyTo)
            this.relatedTweet = relatedTweet
            const latestReply = await TwitterServices.GetTweets({ start: new Date(this.propTweet.createdAt), end: new Date('2100-01-01') }, { replyTo: this.propTweet.id })
            this.replyTweets = latestReply
        }
        this.loading = false
    }

}
